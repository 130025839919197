<template>
  <div class="mx-4">
    <SmartTable
      :sourceUrl="sourceUrl"
      :columns="columns"
    />
  </div>
</template>

<script>
import SmartTable from '@/components/SmartTable'
import moment from 'moment'

export default {
  name: 'Companies',
  components: {
    SmartTable
  },
  data () {
    return {
      sourceUrl: '/sale/company',
      columns: [
        {
          field: 'source',
          headerText: 'Источник'
        },
        {
          field: 'created_at',
          headerText: 'Дата создания',
          valueAccessor (field, data, column) {
            return data[field] ? moment(data[field] * 1000).format('DD.MM.YYYY hh:mm:ss') : ''
          }
        },
        {
          field: 'title',
          headerText: 'Название'
        },
        {
          field: 'responsible_id',
          headerText: 'Ответственный'
        }
      ]
    }
  }
}
</script>

<style>

</style>
