<template>
  <div class="mx-4">
    <SmartTable
      :sourceUrl="sourceUrl"
      :columns="columns"
    />
  </div>
</template>

<script>
import SmartTable from '@/components/SmartTable'

export default {
  name: 'Contacts',
  components: {
    SmartTable
  },
  data () {
    return {
      sourceUrl: '/sale/contact',
      columns: [
        {
          field: 'source',
          headerText: 'Источник'
        },
        {
          field: 'name',
          headerText: 'Имя'
        },
        {
          field: 'last_name',
          headerText: 'Фамилия'
        },
        {
          field: 'second_name',
          headerText: 'Отчество'
        },
        {
          field: 'post',
          headerText: 'Должность'
        },
        {
          field: 'responsible_id',
          headerText: 'Ответственный'
        }
      ]
    }
  }
}
</script>

<style>

</style>
