<template>
  <div>
    <Block title="Личные KPI" desc="Здесь отображаются ваши ключевые показатели за текущий месяц" noline />
    <Block v-for="indicator in indicators" :key="indicator.code" :noline="!indicator.open">
      <div class="block-header">
        <div class="kpi-title">{{ indicator.title }}</div>
        <div class="kpi-value sticker" :class="indicator.valueClass">
          {{ indicator.valueFilter ? indicator.valueFilter(result[indicator.code]) : result[indicator.code] }}
        </div>
        <div class="kpi-arrow"><Arrow :direction="indicator.open ? 'top' : 'bottom'" @arrow-click="open(indicator.code)" /></div>
      </div>
      <div v-if="indicator.open">
        <Bar />
      </div>
    </Block>
  </div>
</template>

<script>
import Vue from 'vue'
import Arrow from '@/components/ui/Arrow'
import Bar from '@/components/sale/Bar'

export default {
  name: 'Kpi',
  components: {
    Arrow,
    Bar
  },
  data () {
    return {
      isLoading: true,
      error: '',
      indicators: [
        {
          title: 'Средний чек',
          code: 'avg_sum',
          open: true,
          valueFilter: (val) => {
            return this.$options.filters.money(val)
          },
          valueClass: 'currency'
        },
        {
          title: 'Длительность сделки',
          code: 'avg_duration',
          open: false,
          valueFilter: (val) => {
            return this.$options.filters.pluralize(val, ['день', 'дня', 'дней'])
          }
        },
        {
          title: 'Количество сделок',
          code: 'open_deals',
          open: false
        }
      ],
      result: []
    }
  },
  async created () {
    try {
      this.result = await this.$api.query('sale/kpi')
    } catch (e) {
      this.error = 'Произошла ошибка при получении данных'
    }

    this.isLoading = false
  },
  methods: {
    open (code) {
      const i = this.indicators.findIndex(el => el.code === code)
      Vue.set(this.indicators[i], 'open', !this.indicators[i].open)
    }
  }
}
</script>

<style scoped>
.block-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.kpi-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--title-color);
}
.kpi-value {
  margin-left: 10px;
}
.kpi-arrow {
  margin-left: auto;
}
</style>
