<template>
  <router-view></router-view>
</template>

<script>
import CanMixin from '@/mixins/CanMixin'

export default {
  name: 'SaleIndex',
  mixins: [CanMixin],
  async created () {
    if (this.$route.name === 'sale') {
      if (this.can('sale_settings')) {
        const isSynced = await this.$api.query('sale/wizard/check-sync')
        const isWizard = await this.$api.query('sale/wizard/check')
        if (!isSynced) {
          await this.$router.push({ name: 'sale/sync' })
        } else if (!isWizard) {
          await this.$router.push({ name: 'sale/wizard' })
        } else {
          await this.$router.push({ name: 'sale/plan/progress' })
        }
      } else {
        await this.$router.push({ name: 'sale/plan' })
      }
    }
  }
}
</script>
